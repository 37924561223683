export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
    meta: {
      requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir", "gudang", "reseller"],
    },
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    badge: {
      variant: "primary",
      text: "menuitems.dashboard.badge",
    },
    link: "/",
    meta: {
      requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir", "gudang", "reseller"],
    },
  },
  {
    id: 3,
    label: "menuitems.pemesanan.text",
    icon: "uil-shopping-cart-alt",
    meta: { requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir"] },
    subItems: [
      {
        id: 4,
        label: "menuitems.pemesanan.list.all",
        link: "/penjualan",
        parentId: 3,
        meta: { requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir"] },
      },
      {
        id: 5,
        label: "menuitems.pemesanan.list.create",
        link: "/penjualan/tambah",
        parentId: 3,
        meta: { requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir"] },
      },
    ],
  },
  {
    id: 7,
    label: "menuitems.pembelian.text",
    icon: "uil-bookmark",
    meta: { requiredAllowedRole: ["owner", "manager", "gudang"] },
    subItems: [
      {
        id: 8,
        label: "menuitems.pembelian.list.all",
        link: "/pembelian",
        parentId: 7,
        meta: { requiredAllowedRole: ["owner", "manager", "gudang"] },
      },
      {
        id: 9,
        label: "menuitems.pembelian.list.create",
        link: "/pembelian/tambah",
        parentId: 7,
        meta: { requiredAllowedRole: ["owner", "manager", "gudang"] },
      },
    ],
  },
  {
    id: 11,
    label: "menuitems.produk.text",
    icon: "uil-pricetag-alt",
    meta: {
      requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir", "gudang", "reseller"],
    },
    subItems: [
      {
        id: 12,
        label: "menuitems.produk.list.all",
        link: "/produk",
        parentId: 11,
        meta: {
          requiredAllowedRole: [
            "owner",
            "manager",
            "kepalaKasir",
            "kasir",
            "gudang",
            "reseller",
          ],
        },
      },
      {
        id: 13,
        label: "menuitems.produk.list.create",
        link: "/produk/tambah",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 32,
        label: "menuitems.produk.list.min-stock",
        link: "/produk/min-stok",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 14,
        label: "menuitems.produk.list.price-type",
        link: "/produk/tipe-harga",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 15,
        label: "menuitems.produk.list.category",
        link: "/produk/kategori",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 16,
        label: "menuitems.produk.list.color",
        link: "/produk/warna",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 17,
        label: "menuitems.produk.list.size",
        link: "/produk/ukuran",
        parentId: 11,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
    ],
  },
  {
    id: 18,
    label: "menuitems.stok.text",
    icon: "uil-store",
    meta: {
      requiredAllowedRole: ["owner", "manager", "kepalaKasir", "kasir", "gudang", "reseller"],
    },
    subItems: [
      {
        id: 19,
        label: "menuitems.stok.list.transaction",
        link: "/stok/transaksi",
        parentId: 18,
        meta: {
          requiredAllowedRole: [
            "owner",
            "manager",
            "kepalaKasir",
            "kasir",
            "gudang",
            "reseller",
          ],
        },
      },
      {
        id: 20,
        label: "menuitems.stok.list.adjustment",
        link: "/stok/penyesuaian",
        parentId: 18,
        meta: { requiredAllowedRole: ["owner"] },
      },
    ],
  },
  {
    id: 21,
    label: "menuitems.laporan.text",
    icon: "uil-invoice",
    meta: { requiredAllowedRole: ["owner", "manager"] },
    subItems: [
      {
        id: 22,
        label: "menuitems.laporan.list.sale",
        link: "/laporan/penjualan",
        parentId: 21,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 23,
        label: "menuitems.laporan.list.purchase",
        link: "/laporan/pembelian",
        parentId: 21,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 24,
        label: "menuitems.laporan.list.popular",
        link: "/laporan/barang-terlaris",
        parentId: 21,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
    ],
  },
  {
    id: 25,
    label: "menuitems.supplier.text",
    icon: "uil-truck",
    meta: { requiredAllowedRole: ["owner", "manager"] },
    subItems: [
      {
        id: 26,
        label: "menuitems.supplier.list.all",
        link: "/supplier",
        parentId: 25,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 27,
        label: "menuitems.supplier.list.create",
        link: "/supplier/tambah",
        parentId: 25,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
    ],
  },
  {
    id: 28,
    label: "menuitems.pelanggan.text",
    icon: "uil-users-alt",
    meta: { requiredAllowedRole: ["owner", "manager"] },
    subItems: [
      {
        id: 29,
        label: "menuitems.pelanggan.list.all",
        link: "/pelanggan",
        parentId: 28,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 30,
        label: "menuitems.pelanggan.list.create",
        link: "/pelanggan/tambah",
        parentId: 28,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 31,
        label: "menuitems.pelanggan.list.category",
        link: "/pelanggan/kategori",
        parentId: 28,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
    ],
  },
  {
    id: 32,
    label: "menuitems.pengguna.text",
    icon: "uil-user-check",
    meta: { requiredAllowedRole: ["owner", "manager"] },
    subItems: [
      {
        id: 33,
        label: "menuitems.pengguna.list.all",
        link: "/pengguna",
        parentId: 32,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
      {
        id: 34,
        label: "menuitems.pengguna.list.create",
        link: "/pengguna/tambah",
        parentId: 32,
        meta: { requiredAllowedRole: ["owner", "manager"] },
      },
    ],
  },
];
